<template>
    <div style="padding: 6rem 3rem; text-align: left;">
        <loading :active.sync="isLoading" 
        :can-cancel="true" 
        :on-cancel="onCancel"
        :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-sm-6">
                <div class="well">
                    <h4>Configurar Oportunidade</h4>
                    <div class="pipeform-layout-config">
                        <h5>Funil</h5>
                        <div class="form-group">
                            <multiselect v-model="piperun_pipeline" :options="pipelines" :searchable="false" :close-on-select="true" :custom-label="pipeline" :show-labels="false" placeholder="Escolha um funil" @input="getStages"></multiselect>
                            <p class="help-block">Selecione o funil para a oportunidade.</p>
                            <div class="alert alert-danger" v-show="$v.piperun_pipeline.$error">
                                <p>
                                    <strong>Atenção!</strong> Você deve configurar o funil de vendas.
                                </p>
                            </div>
                        </div>
                        <h5>Etapa</h5>
                        <div class="form-group">
                            <multiselect v-model="piperun_stage" :options="stages" :searchable="false" :close-on-select="true" :custom-label="stage" :show-labels="false" :disabled="!piperun_pipeline" placeholder="Escolha um funil"></multiselect>
                            <p class="help-block">Selecione a etapa para a oportunidade</p>
                            <div class="alert alert-danger" v-show="$v.piperun_stage.$error">
                                <p>
                                    <strong>Atenção!</strong> Você deve configurar a etapa de funil.
                                </p>
                            </div>
                        </div>
                        <h5>Nome</h5>
                        <div class="form-group">
                            <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="glyphicon glyphicon-pencil"></i> Variáveis
                            </button>
                            <ul class="dropdown-menu pipeform-deal-name">
                                <li v-for="deal_option in deal_template_options" :key="deal_option.name" :value="deal_option.name">
                                    <a href="#" :template="deal_option.attribute" @click="dealNameTemplate">{{ deal_option.value }}</a>
                                </li>
                                <li><a href="#" template="oportunidade.data" @click="dealNameTemplate">Oportunidade - Data</a></li>
                            </ul>
                        </div>
                        <div class="form-group">
                            <input type="text" value="" v-model="deal_title" class="form-control pipeform-deal-title">
                            <p class="help-block">Configure um nome para a oportunidade</p>
                            <div class="alert alert-danger" v-show="$v.deal_title.$error">
                                <p>
                                    <strong>Atenção!</strong> Você deve configurar o nome da oportunidade.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="well">
                    <h4>Configurar Tags</h4>
                    <div class="pipeform-layout-config">
                        <h5>Tags</h5>
                        <div class="form-group">
                            <multiselect v-model="piperun_tags" :multiple="true" :options="tags" :searchable="true" :close-on-select="false" track-by="name" :custom-label="templateTags" :show-labels="false" placeholder="Escolha uma ou mais tags"></multiselect>
                            <p class="help-block">Selecione tags para a oportunidade.</p>
                            <div class="alert alert-danger" v-show="$v.piperun_tags.$error">
                                <p>
                                    <strong>Atenção!</strong> Você deve configurar pelo menos uma tag.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="well">
                    <h4>Configurar Origem</h4>
                    <div class="pipeform-layout-config">
                        <h5>Origens</h5>
                        <div class="form-group">
                            <multiselect v-model="piperun_origin" :options="origins" :searchable="false" :close-on-select="true" :custom-label="templateOrigins" :show-labels="false" placeholder="Escolha uma origem"></multiselect>
                            <p class="help-block">Selecione a origem para a oportunidade.</p>
                            <div class="alert alert-danger" v-show="$v.piperun_origin.$error">
                                <p>
                                    <strong>Atenção!</strong> Você deve configurar pelo menos uma origem.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="well">
                    <h4>Configurar Regras de Oportunidade</h4>
                    <div class="pipeform-layout-config">
                        <h5>Permite atualizar oportunidade já existente?</h5>
                        <div class="form-group">
                            <bootstrap-toggle v-model="update" :options="{ on: 'Sim', off: 'Não', onstyle: 'success', offstyle: 'info', width: '100%' }" :disabled="false" />
                            <p class="help-block">Determina a criação de uma nova oportunidade ou atualizar uma já existente de acordo com o ID do lead.</p>
                        </div>
                        <div v-if="update">
                            <h5>Determine o identificador do lead</h5>
                            <div class="form-group">
                                <multiselect v-model="identifier" track-by="name" :custom-label="templateIdentifier" placeholder="Escolha um identificador" :options="identifiers" :searchable="false" :show-labels="false">
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.value }} <span class="label label-primary">{{ option.type }}</span></template>
                                </multiselect>
                                <p class="help-block">Determinar o dado que identifica o lead dentro do PipeRun.</p>
                            </div>
                            <h5>Qual o critério de status?</h5>
                            <div class="form-group">
                                <multiselect v-model="status_update" :multiple="true" :options="status" :searchable="false" :close-on-select="false" label="value" track-by="name" :show-labels="false" placeholder="Escolha os status" @input="checkOpenStatus"></multiselect>
                                <p class="help-block">Se já houver uma oportunidade com o status informado e com o mesmo ID, será atualizada a oportunidade. Caso contrário, uma nova oportunidade será criada.</p>
                            </div>
                            <div v-if="open_status">
                                <h5>Atualizar Situação da Oportunidade</h5>
                                <div class="form-group">
                                    <multiselect v-model="situation_update" :multiple="true" :options="situations" :searchable="false" :close-on-select="false" label="value" track-by="name" :show-labels="false" placeholder="Escolha as situações"></multiselect>
                                    <p class="help-block">Se já houver uma oportunidade com a situação informada e com o mesmo ID, será atualizada a oportunidade. Caso contrário, uma nova oportunidade será criada.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="cpf_field && cnpj_field">
                        <h4>Configurar Regras de Pessoa e Empresa</h4>
                        <div class="pipeform-layout-config">
                            <h5>Validar CPF</h5>
                            <div class="form-group">
                                <bootstrap-toggle v-model="validate_cpf" :options="{ on: 'Sim', off: 'Não', onstyle: 'success', offstyle: 'info', width: '100%' }" :disabled="false" />
                                <p class="help-block">Validar por CPF antes de criar a pessoa.</p>
                            </div>
                            <h5>Validar CNPJ</h5>
                            <div class="form-group">
                                <bootstrap-toggle v-model="validate_cnpj" :options="{ on: 'Sim', off: 'Não', onstyle: 'success', offstyle: 'info', width: '100%' }" :disabled="false" />
                                <p class="help-block">Validar por CNPJ antes de criar a empresa.</p>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="cpf_field">
                        <h4>Configurar Regra de Pessoa</h4>
                        <div class="pipeform-layout-config">
                            <h5>Validar CPF</h5>
                            <div class="form-group">
                                <bootstrap-toggle v-model="validate_cpf" :options="{ on: 'Sim', off: 'Não', onstyle: 'success', offstyle: 'info', width: '100%' }" :disabled="false" />
                                <p class="help-block">Validar por CPF antes de criar a pessoa.</p>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="cnpj_field">
                        <h4>Configurar Regra de Empresa</h4>
                        <div class="pipeform-layout-config">
                            <h5>Validar CNPJ</h5>
                            <div class="form-group">
                                <bootstrap-toggle v-model="validate_cnpj" :options="{ on: 'Sim', off: 'Não', onstyle: 'success', offstyle: 'info', width: '100%' }" :disabled="false" />
                                <p class="help-block">Validar por CNPJ antes de criar a empresa.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import http from '../plugins/axios'
import BootstrapToggle from 'vue-bootstrap-toggle'
import Multiselect from 'vue-multiselect'

// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

// Validations
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'ChoosePipeline',
    mixins: [
        validationMixin
    ],
    props: ['clickedNext', 'currentStep'],
    data() {
        return {
            cpf_field: false,
            cnpj_field: false,
            validate_cpf: false,
            validate_cnpj: false,
            pipelines: [],
            stages: [],
            tags: [],
            origins: [],
            piperun_pipeline: null,
            piperun_stage: null,
            piperun_tags: [],
            piperun_origin: null,
            deal_title: '',
            deal_template_options: [],
            isLoading: false,
            fullPage: true,
            update: true,
            state: 'open',
            status_update: [{ name: 'open', value: 'Aberta' }],
            status: [
                { name: 'open', value: 'Aberta' },
                { name: 'closed', value: 'Fechada' },
                { name: 'lost', value: 'Perdida' }
            ],
            open_status: true,
            situation: 'unfreezed',
            situation_update: [{ name: 'unfreezed', value: 'Normal' }],
            situations: [
                { name: 'unfreezed', value: 'Normal' },
                { name: 'freezed', value: 'Congelada' },
                { name: 'deleted', value: 'Lixeira' }
            ],
            identifier: [],
            identifiers: []
        }
    },
    validations: {
        piperun_pipeline: { required },
        piperun_stage: { required },
        piperun_tags: { required },
        piperun_origin: { required },
        deal_title: { required },
    },
    components: {
        Loading,
        BootstrapToggle,
        Multiselect
    },
    methods: {
        async getPipelines() {
            this.isLoading = true

            http.get('/pipelines?show=200&page=0')
                .then(response => {
                    this.isLoading = false
                    this.pipelines = response.data.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getTags() {
            http.get('/tags?belongs=1&show=200&page=0')
                .then(response => {
                    this.tags = response.data.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getOrigins() {
            http.get('/origins?show=200&page=0')
                .then(response => {
                    this.isLoading = false
                    this.origins = response.data.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getStages() {
            this.isLoading = true

            http.get('/stages?pipeline_id=' + this.piperun_pipeline.id)
                .then(response => {
                    this.isLoading = false
                    this.stages = response.data.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        onCancel() {
            console.log('Usuário cancelou o carregamento.')
        },
        pipeline({ name }) {
            return `${name}`
        },
        stage({ name }) {
            return `${name}`
        },
        templateOrigins({ name }) {
            return `${name}`
        },
        templateTags({ name }) {
            return `${name}`
        },
        checkOpenStatus(value) {
            value.find((element) => {
                if (element.name === 'open') {
                    this.open_status = true
                    return true
                } else {
                    this.open_status = false
                    return false
                }
            })

            if (value.length === 0) {
                this.open_status = false
                return false
            }
        },
        templateIdentifier({ value, type }) {
            return `${value} — ${type}`
        },
        dealNameTemplate(event) {
            event.preventDefault()
            this.deal_title += '{{ ' + event.target.attributes.template.value + ' }}'
        }
    },
    created() {
        http.defaults.headers.token = this.$cookie.get('user_token')
        this.getPipelines()
        this.getTags()
        this.getOrigins()
    },
    watch: {
        $v: {
            handler: function (val) {
                if(!val.$invalid) {
                    this.$emit('can-continue', { value: true });
                } else {
                    this.$emit('can-continue', { value: false });
                    
                    setTimeout(()=> {
                        this.$emit('change-next', { nextBtnValue: false });
                    }, 3000)
                }
            },
            deep: true
        },
        clickedNext(val) {
            console.log(val);
            if (val === true) {
                this.$v.$touch();
            }
        }
    },
    mounted() {
        if (!this.$v.$invalid) {
            this.$emit('can-continue', {value: true});
        } else {
            this.$emit('can-continue', {value: false});
        }

        var fields = JSON.parse(localStorage.getItem('fields'))

        fields.forEach(element => {
            if (element.field !== 'notes' && element.field !== 'company_phone_main' && element.field !== 'person_phone_main') {
                this.identifiers.push({ name: element.field, type: element.type, value: element.name })
                this.deal_template_options.push({ name: element.field, attribute: element.template, value: element.name + ' - ' + element.type })
            }
        })
    },
    updated() {
        localStorage.setItem('stage_hash', (this.piperun_stage !== null) ? this.piperun_stage.hash : '')
        localStorage.setItem('tags', JSON.stringify(this.piperun_tags))
        localStorage.setItem('update', this.update)
        localStorage.setItem('state', this.state)
        localStorage.setItem('situation', this.situation)
        localStorage.setItem('origin', (this.piperun_origin !== null) ? this.piperun_origin.name : '')
        localStorage.setItem('deal_title', document.getElementsByClassName('pipeform-deal-title')[0].value)
        localStorage.setItem('identifier', JSON.stringify(this.identifier))
        localStorage.setItem('status_update', JSON.stringify(this.status_update))
        localStorage.setItem('situation_update', JSON.stringify(this.situation_update))
        localStorage.setItem('validate_cpf', this.validate_cpf)
        localStorage.setItem('validate_cnpj', this.validate_cnpj)
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
    .dropdown-menu.pipeform-deal-name {
        top: initial !important;
        left: initial !important;
    }
</style>